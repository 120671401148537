export const faqList = [
  {
    "question": "切り抜きについて",
    "answer":
        "広告等の収益化をしていただいて構いません。\n カットのみの切り抜き投稿はご遠慮ください。\n 概要欄に本動画へのリンクを貼ってください"
  },
  {
    "question": "二次創作について",
    "answer":
        "小説、漫画、イラスト、コスプレ、動画の創作は大歓迎です。 \n立体物の配布・販売ご遠慮ください(個人での使用は問題ありません)。\n"
  },
  {
    "question": "R-18の二次創作について",
    "answer":
        "未成年の方が見れないように注意してください。 \n 素材改変はご遠慮ください。\n 本人たちへのタグ付け、直接のリプ等はしないでください。"
  }
];
