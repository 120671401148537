<template>
  <div id="FAQ" >
    <AppHeader />
    <PageTitle title="FAQ" />
    <ul>
      <li v-for="faq in faqs" :key="faq.id" class="faq">
        <p> Q. {{ faq.question }} </p>
        <p> A. {{ faq.answer }} </p>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import AppHeader from '../components/AppHeader.vue'
import PageTitle from '../components/PageTitle.vue'
import { faqList } from '../lib/faq'
import {createDefaultTitle} from '../lib/util';

export default{
  name: "FAQ",
  components: {
    AppHeader,
    PageTitle
  },
  data(){
    return {
      faqs: []
    }
  },
  created(){
    createDefaultTitle();
    this.faqs = faqList;
  }
}
</script>


<style scoped>
  li.faq{
    list-style: none;
    border-bottom: 1px solid #000;
  }

  li p{
    text-align: left;
    margin: 15px auto 15px 0;
    word-break: keep-all;
    overflow-wrap: break-word;
  }
</style>
